(function ($) {
  'use strict';

  window.DGS.OnContentLoaded.getInstance().register(function () {
    var smallestImgSize = 900;

    // deal with Lottie animations
    // need to enable script below to test on localhost
    // $('<script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>').appendTo('head')
    $('.component.image-spot.with-lottie-icons .image-spot-element-image').each(function () {
      if ($(this).find('.image-spot-element-image-description').length) {
        var lootieText = $(this).find('p.image-spot-element-image-description')[0].innerText;
        $(lootieText).appendTo(this);
        $(this).children('img').remove();
        $(this).children('a').remove();
        $(this).children('.image-spot-element-image-description').remove();
      }
    });
    $('.component.image-spot.all-equal-image .image-spot-element-image').each(function () {
      var vm = $(this);
      if (vm.find('img').height() < smallestImgSize && vm.find('img').height() != 0) {
        smallestImgSize = vm.find('img').height();
      }
    });
    $('.component.image-spot.all-equal-image .image-spot-element-image img').height(smallestImgSize);
    $('.component.image-spot.title-above').each(function () {
      var vm = $(this);
      var maxHeight = 0;
      window.DGS.EqualHeight.getInstance().register($('.image-spot-element-image', vm));
      window.DGS.EqualHeight.getInstance().register($('.image-spot-element-header', vm));
      $('.image-spot-element-header', vm).each(function () {
        var thisH = $(this).height() + parseInt($(this).css('margin-top')) + parseInt($(this).css('margin-bottom'));
        if (thisH > maxHeight) {
          maxHeight = thisH;
        }
      });
      $('.image-spot-element-image', vm).css('padding-top', maxHeight);
    });
    $('.component.img-spot-carousel').each(function () {
      if (!$('body').hasClass('on-page-editor')) {
        // Check if touch device

        var isTouchDevice = false;
        if ('ontouchstart' in window || navigator.msMaxTouchPoints > 0) {
          window.addEventListener('touchstart', function () {
            isTouchDevice = true;
          }, true);
        } else {
          isTouchDevice = false;
        }
        if ($('body').width() > 767) {
          var carousel = $(this);
          var tracker = 1;
          var fromLeft = 0;
          var slidesNo = carousel.find('ul.image-spot-elements').children('li').length;
          var slidesNoArray = slidesNo - 1;
          init(carousel);
          carousel.find('ul.image-spot-elements').children('li').eq(0).addClass('activeSlide'); // Content load init only

          carousel.mouseenter(function () {
            if (!isTouchDevice) {
              carousel.addClass('dontMove');
              window.clearInterval(animationInterval);
            }
          });
          carousel.mouseleave(function () {
            if (!isTouchDevice) {
              carousel.removeClass('dontMove');
              animationInterval = window.setInterval(cAnimation, 5000);
            }
          });
          carousel.find('.image-spot-element-link > a').click(function (e) {
            e.preventDefault();
            e.stopPropagation();
            tracker = Number($(this).parent().attr('slideNo'));
            cAnimation(true); // forced = true
          });
          var cAnimation = function cAnimation(forced) {
            if (!carousel.hasClass('dontMove') || forced) {
              if (tracker > slidesNoArray) {
                tracker = 0;
              }
              fromLeft = -100 * tracker;
              carousel.find('ul.image-spot-elements').children('li').removeClass('activeSlide');
              carousel.find('ul.image-spot-elements').children('li').eq(tracker).addClass('activeSlide');
              carousel.find('ul.image-spot-elements').css('margin-left', fromLeft + '%');
              tracker++;
            }
          };
          var animationInterval = window.setInterval(cAnimation, 5000);
        }
      }
    });
  });
  window.DGS.Resizer.getInstance().register(function () {
    $('.component.img-spot-carousel').each(function () {
      if ($('body').width() > 767 && !$('body').hasClass('on-page-editor')) {
        init($(this));
      }
    });
  });
  var init = function init(domItem) {
    var slidesNo = domItem.find('ul.image-spot-elements').children('li').length;
    var percentW = slidesNo * 100;
    var minHeight = 2000;
    domItem.find('ul.image-spot-elements').width(percentW + '%');
    domItem.find('li.image-spot-element div.image-spot-element-link a').text('');
    domItem.find('ul.image-spot-elements').children('li').width(domItem.find('ul.image-spot-elements').width() / slidesNo);
    for (var i = 0; i < slidesNo; i++) {
      var indented = -slidesNo / 2 + 0.5 + i;
      var thisLiItem = domItem.find('ul.image-spot-elements').children('li').eq(i);
      thisLiItem.children('.image-spot-element-link').css('margin-left', indented + 'rem');
      if (thisLiItem.children('.image-spot-element-link').length > 0) {
        thisLiItem.children('.image-spot-element-link').attr('slideNo', i);
      } else {
        thisLiItem.append('<div class="image-spot-element-link" slideno="' + i + '"><a href="#"></a></div>');
      }
      thisLiItem.children('.image-spot-element-header, .image-spot-element-text').wrapAll('<div class="carousel-absolute"></div>');
      thisLiItem.children('.carousel-absolute').wrap('<div class="carousel-content"></div>');
    }
    domItem.find('ul.image-spot-elements > li.image-spot-element .image-spot-element-image img').each(function () {
      var thisSlideSize = $(this);
      if (minHeight > thisSlideSize.height()) {
        minHeight = thisSlideSize.height();
      }
    });
    var minHeightPad = minHeight + parseInt(domItem.css('padding-top')) + parseInt(domItem.css('padding-bottom'));
    domItem.innerHeight(minHeightPad);
    domItem.find('.carousel-content').height(minHeight);
    domItem.find('ul.image-spot-elements > li').height(minHeight);
    domItem.children('.component-content').css('opacity', 1);
  };
})(jQuery);