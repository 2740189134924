(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    if ($('.add-parallax > .component-content .banner-image').length > 0) $('.add-parallax > .component-content .banner-image').addClass('is-parallax');
    var class2add = ['full', 'half', 'third', 'fourth'],
      cl = $('.banner-boxes > ul > li').size() - 1,
      $ctaBoxes = $('.banner-spot-elements'),
      $ctaContainer = $('.banner-spot-content'),
      $bannerBoxes = $('.banner-boxes'),
      bh = $ctaContainer.height() - $ctaBoxes.height();
    $('.banner-boxes > ul > li').addClass(class2add[cl] + ' left').wrapInner('<div class="usp-content"></div>');
    $('.banner-boxes > ul > li > .usp-content').each(function () {
      if ($(this).find('a').attr('href') !== undefined) {
        $(this).wrap("<a href='" + $(this).find('a').attr('href') + "'></a>");
      }
    });
    $('.add-usps-class > .component-content > ul > li > div').on('click', function () {
      var thisLink = $(this).find('a'),
        thisLinkHref = thisLink.attr('href');
      window.location.href = thisLinkHref;
    });
    window.DGS.EqualHeight.getInstance().register($('.usp-content .image-spot-element-text'), true);
    window.DGS.Resizer.getInstance().register(function () {
      bh = $ctaContainer.height() - $ctaBoxes.height();
      $bannerBoxes.css('top', bh);
    });
    $('.banner-spot').each(function () {
      var $this = $(this),
        $bText = $('.banner-text', $this),
        $ctas = $('.banner-boxes', $this);
      window.DGS.Overflow.getInstance().register($this, $('.banner-text', $this), function () {
        var rect1 = $bText[0].getBoundingClientRect(),
          rect2 = $ctas[0].getBoundingClientRect();
        if (!(rect1.right < rect2.left || rect1.left > rect2.right || rect1.bottom < rect2.top || rect1.top > rect2.bottom)) {
          return true;
        }
        return false;
      });
    });
  });
})(jQuery);