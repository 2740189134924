require('core-js');require('regenerator-runtime/runtime');// webpack
// require("../../../wdh-foundation/sms/sms-api.js");
import $ from "jquery";
window.jQuery = $;
window.$ = $;
import { TweenMax } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
require("../../../wdh-foundation/dateformat/dateformat.js");
require("../../../wdh-foundation/dgs/dgs-api.js");
require("../../../wdh-foundation/dgs/dgs-events.js");

// blog
require("./feature/blog/blog.js");
require("@demant/megamenu");
require("../../../wdh-foundation/dgs-utils/dgs-utils.js");
require("@demant/legacy/googlemaps");
require("iframe-resizer");
require("jquery-nice-select/js/jquery.nice-select.min.js");
require("jquery-ui-dist/jquery-ui.min.js");
require("lightgallery/src/js/lightgallery.js");
require("@demant/legacy/lunametrics");
require("jquery-bridget");
require("masonry-layout");
require("moment");
require("./feature/component-search.js");
require("./feature/component-banner-spot.js");
require("./feature/component-box-spot.js");
require("./feature/component-color-selector-spot.js");
require("./feature/component-contact-information.js");
require("./feature/component-cookies.js");
require("./feature/component-country-selector.js");
require("./feature/data-hierarchy-spot/component-a-data-hierarchy.js");
require("./feature/data-hierarchy-spot/component-b-data-hierarchy-appframe-component.js");
require("./feature/data-hierarchy-spot/component-b-data-hierarchy-hubspot.js");
require("./feature/data-hierarchy-spot/component-b-data-hierarchy-multi-step.js");
require("./feature/data-hierarchy-spot/component-b-data-hierarchy-resource-center.js");
require("./feature/data-hierarchy-spot/component-data-hierarchy-dataset.js");
require("./feature/component-distributor-locator.js");
require("./feature/component-download-center.js");
require("./feature/component-hearing-center-locator.js");
require("./feature/component-iframe.js");
require("./feature/component-image-spot-base.js");
require("./feature/component-intro-banner.js");
require("./feature/component-job-list-spot.js");
require("./feature/component-lattice-spot.js");
require("./feature/component-link-list.js");
require("./feature/component-testimonial-spot.js");
require("./feature/component-news-list-spot.js");
require("./feature/online-booking/a-datepicker-regionals.js");
require("./feature/online-booking/component-online-booking.js");
require("./feature/component-option-spot.js");
require("./feature/product-tool/colour-selector.js");
require("./feature/product-tool/hammer.js");
require("./feature/product-tool/jqueryhammer-full.js");
require("./feature/product-tool/knockout-310.js");
require("./feature/product-tool/model-selector.js");
require("./feature/component-leaving-notification-button.js");
require("./feature/component-leaving-notification.js");
require("./feature/component-richtext-submenu.js");
require("./feature/component-richtext-table.js");
require("./feature/component-screening-test.js");
require("./feature/component-spot-control-spot.js");
require("./feature/component-submenu.js");
require("./feature/component-text-image-spot.js");
require("./feature/component-video-spot.js");
require("./feature/component-b-data-hierarchy-resource-center.js");
require("./feature/component-extranet.js");
require("./feature/component-image-spot.js");
require("./feature/component-vertical-line-spot.js");
require("./feature/component-accordion-spot.js");
require("./feature/component-flexspot-expandable-intro-banner.js");
require("./feature/component-flexspot-timeline-carousel.js");
require("./feature/component-flexspot-pardot-form");
require("./feature/component-flexspot-products-slider.js");
require("./feature/component-flexspot-video-library.js");
require("./feature/language-selector-spot");
require("../../base/js/base-main-custom.js");
require("../../base/js/tracking-gtm.js");
require("./custom-footer.js");
require("./custom-header.js");
require("@demant/wdh-gdpr/lib/default.implementation.js");