(function ($, DGS) {
  "use strict";

  DGS.OnLoad.getInstance().register(function () {
    $('.component.blog-list').each(function () {
      $('#blog-list li', $(this)).each(function () {
        // Now change the date format
        var $newsDate = $(".news-date", $(this));

        // Moves the date to after the image
        $newsDate.insertAfter($(".news-image", $(this)), null);
      });
    });
  });
})(jQuery, window.DGS);