/* eslint-disable func-names prefer-arrow-callback */
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    var clearPrev;

    // set desktop breakpoint
    var desktopBreakpoint = 1025;
    var isDesktop = $("body").width() >= desktopBreakpoint;
    function lockScroll() {
      // lock scroll position, but retain settings for later
      var scrollPosition = [self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
      // eslint-disable-line no-restricted-globals
      self.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // eslint-disable-line no-restricted-globals
      ];
      var html = $("html");
      html.data("scroll-position", scrollPosition);
      html.data("previous-overflow", html.css("overflow"));
      html.css("overflow", "hidden");
      window.scrollTo(scrollPosition[0], scrollPosition[1]);
    }
    function unlockScroll() {
      // un-lock scroll position
      var html = $("html");
      var scrollPosition = html.data("scroll-position");
      html.css("overflow", html.data("previous-overflow"));
      window.scrollTo(scrollPosition[0], scrollPosition[1]);
      window.DGS.Resizer.getInstance().execute();
    }
    function closeOverlay() {
      clearPrev = undefined;
      var $overlay = $("#wrapper .video-spot-overlay");
      var $overlayVideo = $(".video-spot-overlay-video", $overlay);
      // $('#header, #content, #footer').removeClass('full-blur');
      $overlay.fadeOut("fast");
      $overlayVideo.html("");
      unlockScroll();
      $(window).off("focusout");
    }
    function removeInlinePlayer() {
      clearPrev = undefined;
      $(".video-spot").each(function () {
        var $this = $(this);
        var $inlineVideoContainer = $(".inline-video-container", $this);
        if ($inlineVideoContainer.length) {
          $inlineVideoContainer.remove();
        }
        $this.removeClass("play-inline");
        $(window).off("scroll.animateVideoPosition");
      });
    }
    function showOverlay() {
      var $overlay = $("#wrapper .video-spot-overlay");
      $overlay.fadeIn("fast");
      // $('#header, #content, #footer').addClass('full-blur');
      lockScroll();
      $(window).focusout(function () {
        $(window).focus();
      });
      $(document).keyup(function (e) {
        if (e.keyCode === 27) {
          closeOverlay();
        }
      });
    }
    function initOverlay() {
      $("#wrapper").append('<div class="video-spot-overlay"><div class="video-spot-overlay-close"></div><div class="video-spot-overlay-video"></div></div>');
      var $overlay = $(".video-spot-overlay");
      var $close = $(".video-spot-overlay-close", $overlay);
      $overlay.click(closeOverlay);
      $close.click(closeOverlay);
    }
    function decorateEmbedCode(embedCode) {
      if (embedCode.indexOf("youtube") !== -1 && embedCode.indexOf("enablejsapi=1") === -1) {
        var sep = embedCode.indexOf("?") === -1 ? "?" : "&";
        return embedCode.replace(
        // /data-consent-src="([^"]+)"/gi, // use this line on localhost
        /src="([^"]+)"/gi, "src=\"$1".concat(sep, "enablejsapi=1&rel=0\""));
      }
      return embedCode;
    }
    function resizeVideo() {
      var $overlay = $("#wrapper .video-spot-overlay");
      var $overlayVideo = $(".video-spot-overlay-video", $overlay);
      var $iframe = $("iframe", $overlayVideo);
      var w = $overlayVideo.attr("data-width");
      var h = $overlayVideo.attr("data-height");
      var prop;
      var wW = $(window).width();
      var wH = $(window).height();
      var maxMultiplier = 0.8;
      if (w !== undefined && h !== undefined && $iframe.length) {
        prop = w / h;
        w = wW * maxMultiplier;
        h = w / prop;
        if (h > wH * maxMultiplier) {
          h = wH * maxMultiplier;
          w = h * prop;
        }
        $iframe.attr("width", w);
        $iframe.attr("height", h);
      }
    }
    function resizeVideoToInlinePlay() {
      $(".video-spot").each(function () {
        var $this = $(this);
        var $overlayVideo = $(".inline-video-container", $this);
        var $videoImageContainer = $(".video-spot-video", $this);
        var $iframe = $("iframe", $overlayVideo);
        var w = $overlayVideo.attr("data-width");
        var h = $overlayVideo.attr("data-height");
        var prop;
        var wW = $videoImageContainer.width();
        var wH = $videoImageContainer.height();
        var maxMultiplier = 1;
        if (w !== undefined && h !== undefined && $iframe.length) {
          if (w.indexOf("%") > -1) {
            w = parseInt(w, 10) / 100 * window.innerWidth;
          }
          if (h.indexOf("%") > -1) {
            h = parseInt(h, 10) / 100 * window.innerHeight;
          }
          prop = w / h;
          w = wW * maxMultiplier;
          h = w / prop;
          if (h > wH * maxMultiplier) {
            h = wH * maxMultiplier;
            w = h * prop;
          }
          $iframe.attr("width", w);
          $iframe.attr("height", h);
        }
      });
    }

    // deal with video player states
    function onPlayerStateChange(e, videoContainer) {
      // check if video is playing, can't simply toggle because then video ending is not supported
      if (e.data === YT.PlayerState.PLAYING) {
        videoContainer.classList.add("isPlaying");
      } else {
        videoContainer.classList.remove("isPlaying");
      }
    }
    function onYouTubeIframeAPIReady() {
      var videoItem = document.querySelector(".inline-video-container > iframe");

      // only for inline videos
      if (videoItem) {
        var videoContainer = videoItem.parentElement;
        new YT.Player(videoItem, {
          events: {
            onStateChange: function onStateChange(e) {
              return onPlayerStateChange(e, videoContainer);
            }
          }
        });
      }
    }
    window.DGS.Event.getInstance().on("video-play", onYouTubeIframeAPIReady);
    function handleScroll(offsetTop, videoElementHeight, videoContainer) {
      var scrolledOverVideo = $(window).scrollTop() > offsetTop + videoElementHeight / 2;
      var videoIsPlaying = videoContainer.hasClass("isPlaying");
      videoContainer.toggleClass("scale-down", scrolledOverVideo && videoIsPlaying);
    }
    function animateVideoPosition(videoContainer, videoElement) {
      var offsetTop = videoElement.offset().top;
      var videoElementHeight = videoElement.outerHeight();
      var videoContainerWidth = videoContainer.outerWidth();
      var videoContainerHeight = videoContainer.outerHeight();

      // add 'scale-down' class on scroll
      $(window).on("scroll.animateVideoPosition", function () {
        return handleScroll(offsetTop, videoElementHeight, videoContainer);
      });

      // scale according to container size
      var scaleX = 496 / videoContainerWidth;
      var scaleY = 279 / videoContainerHeight;
      $('<style>').text(".inline-video-container.scale-down {transform: scaleX(" + scaleX + ") scaleY(" + scaleY + ")}").appendTo(document.head);

      // add close icon
      var closeIcon = $('<div class="close-icon"><span></span></div>');
      $(".inline-video-container").append(closeIcon);
      $(".close-icon").on("click", removeInlinePlayer);
    }
    function setupVideos() {
      var handleVideoClick = function handleVideoClick(embedCode, isInline, $spot) {
        if (!$("body").hasClass("on-page-editor")) {
          var hRegEx = /iframe.*height=["|']([0-9]+%?)/i;
          var wRegEx = /iframe.*width=["|']([0-9]+%?)/i;
          var $overlay = $("#wrapper .video-spot-overlay");
          var $overlayVideo = $(".video-spot-overlay-video", $overlay);
          embedCode = embedCode.replace(/\[/g, "<").replace(/\]/g, ">");
          var hMatch = embedCode.match(hRegEx);
          var wMatch = embedCode.match(wRegEx);
          var $videoContainer;
          if (!isInline) {
            clearPrev = closeOverlay;
            if (hMatch !== undefined && hMatch.length > 1) {
              $overlayVideo.attr("data-height", hMatch[1]);
            } else {
              $overlayVideo.removeAttr("data-height");
            }
            if (wMatch !== undefined && wMatch.length > 1) {
              $overlayVideo.attr("data-width", wMatch[1]);
            } else {
              $overlayVideo.removeAttr("data-width");
            }
            $overlayVideo.html(decorateEmbedCode(embedCode));
            resizeVideo();
            showOverlay();
          } else {
            clearPrev = removeInlinePlayer;
            $videoContainer = $(".video-spot-container", $spot);
            var $inlineVideoContainer = $(".inline-video-container", $videoContainer);
            if (!$inlineVideoContainer.length) {
              $inlineVideoContainer = $('<div class="inline-video-container"></div>');
              $videoContainer.append($inlineVideoContainer);
            }
            $spot.addClass("play-inline");
            if (hMatch !== undefined && hMatch.length > 1) {
              $inlineVideoContainer.attr("data-height", hMatch[1]);
            } else {
              $inlineVideoContainer.removeAttr("data-height");
            }
            if (wMatch !== undefined && wMatch.length > 1) {
              $inlineVideoContainer.attr("data-width", wMatch[1]);
            } else {
              $inlineVideoContainer.removeAttr("data-width");
            }
            $inlineVideoContainer.html(decorateEmbedCode(embedCode));
            resizeVideoToInlinePlay($inlineVideoContainer, $(".video-spot-video", $spot));
            if (isDesktop) {
              animateVideoPosition($inlineVideoContainer, $inlineVideoContainer.find("> iframe"));
            }
          }
          window.DGS.Event.getInstance().trigger("video-play");
        }
      };
      var setupClicks = function setupClicks() {
        $(".video-spot").each(function () {
          var $spot = $(this);
          var $videos = $(".video-spot-video", $spot);
          var $embedCodes = $("code.embed-code", $spot);
          $embedCodes.each(function () {
            var $this = $(this);
            var html = $this.html();
            var fixedHtml = html.replace(/</g, "[").replace(/>/g, "]");
            $this.text(fixedHtml);
          });
          $videos.each(function () {
            var $video = $(this);
            var $playItems = $(".video-spot-image, .video-spot-play-btn", $video);
            $playItems.click(function (e) {
              // eslint-disable-line prefer-arrow-callback
              e.preventDefault();
              if (clearPrev) {
                clearPrev();
              }
              var embedCode = $(".embed-code", $video).text();
              var embedCodeHtml = $(".embed-code", $video).html();
              var useEmbedCode = embedCode !== "" && embedCode !== undefined ? embedCode : embedCodeHtml;
              handleVideoClick(useEmbedCode, !$spot.hasClass("play-in-popup"),
              // this class can be added in Sitecore
              $spot);
            });
          });
        });
        $("#wrapper > #content .component > .component-content a").each(function () {
          // LOOKS AT ALL a TAGS ON PAGE. ONLY ADDS CLICK TO LINKS THAT HAVE AN IFRAME EMBED CODE IN TITLE ATTRIBUTE.
          // OD-992, OD-1471

          var $this = $(this);
          var dataEmbed = $this.attr("data-embed");
          var title = dataEmbed && dataEmbed.indexOf("iframe") !== -1 ? dataEmbed : $this.attr("title");
          if (title && title.indexOf("iframe") !== -1) {
            title = title.replace(/\</g, "[").replace(/\>/g, "]").replace(/"/g, "'"); // eslint-disable-line no-useless-escape
            $this.data("embed", title);
            $this.attr("title", "");
            $this.addClass("has-video");
            $this.click(function (e) {
              e.preventDefault();
              var $link = $(this);
              var embedCode = $link.data("embed").replace(/\[/g, "<").replace(/\]/g, ">");
              var useEmbedCode = unescape(embedCode);
              handleVideoClick(useEmbedCode, false);
            });
          }
        });
      };
      window.DGS.Event.getInstance().on("download-center-tab-init", setupClicks);
      window.DGS.Event.getInstance().on("link-embed-code-detected", setupClicks);
      setupClicks();
    }
    initOverlay();
    setupVideos();
    window.DGS.Resizer.getInstance().register(resizeVideo);
    window.DGS.Resizer.getInstance().register(resizeVideoToInlinePlay);
  });
})(jQuery);