(function ($, DGS) {
  "use strict";

  DGS.OnLoad.getInstance().register(function () {
    if ($('.component.blog').length) {
      $('.blog-content p:first:has(.bigger, strong)').addClass('blog-introduction');
      $('.component.blog').each(function () {
        // Moving around some elements to fit the design
        var $newHeadline = $("<div class='blog-headline'></div>");
        // Have to append one by one to ensure the correct order
        $newHeadline.append($(".blog-title", $(this))).append($(".blog-author", $(this))).append($(".blog-date", $(this))).append($(".blog-reading-time", $(this)));
        $newHeadline.prependTo($(".blog-image", $(this)), null);
      });
      $('.blog-introduction').clone().insertAfter($('.blog-headline h1'));
    }
    $('.component.related-blogs, .component.blog-list, .component.latest-blog-posts').each(function () {
      var postConfig = {
        characterLength: (parseFloat($('.news-text').css('width').replace('px', '')) / 1.35).toFixed(0),
        lines: 5
      };
      var $this = $(this);
      var $relatedPosts = $('.component-content ul > li > div, ul#blog-list li', $this);
      if ($('.component.blog-list ul#blog-list li').length) {
        postConfig.characterLength = parseFloat($('.news-text').css('width').replace('px', '')) / 1.7;
      }
      $relatedPosts.each(function (i, relatedPost) {
        var $newElement = $(relatedPost).find('.news-text');
        var calculateHeightPost = parseFloat($($newElement).css('height'));
        var lineHeightElement = parseFloat($($newElement).css('line-height'));
        var copyText = $($newElement).text();
        //waiting for all styles and fonts 
        setTimeout(function () {
          if (calculateHeightPost / lineHeightElement > postConfig.lines && copyText.length > postConfig.characterLength) {
            var newsText = copyText.substring(0, postConfig.characterLength);
            $($newElement).css('max-height', lineHeightElement * postConfig.lines);
            $($newElement).html(newsText.substring(0, newsText.lastIndexOf(' ')) + '...');
            var questionMark = $($newElement).text().lastIndexOf('?') === -1 ? $($newElement).text().lastIndexOf(',') : $($newElement).text().lastIndexOf('?');
            if (questionMark > $($newElement).text().length - 5) {
              $($newElement).html($($newElement).text().substring(0, questionMark) + '...');
            }
          }
        }, 1000);
      });
    });
  });
})(jQuery, window.DGS);