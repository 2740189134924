(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('#wrapper .component.vertical-line-spot').each(function () {
      var vm = $(this);
      vm.find('.component-content > ul > li').each(function () {
        var $thisLi = $(this);
        var colorParent = $thisLi.find('.vertical-line-item-body .timeline-inverted').length;
        if (colorParent) {
          $thisLi.addClass('timeline-inverted');
          $thisLi.children('.vertical-line-item-image').addClass('timeline-inverted');
        }
      });
    });
  });
})(jQuery);