(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    if (!$("#wrapper .cookie-warning").length) {
      // There are no sitecore cookie components on page. Break out
      return;
    }
    $("#wrapper .cookie-warning").insertBefore('#header');
    var cookieWarning = $("#wrapper .cookie-warning:not(.initialized)"),
      isEmpty = cookieWarning.find(".component-content").children().length;
    var tween = null;
    if (isEmpty) {
      cookieWarning.addClass("initialized component-ready");
    } else {
      cookieWarning.remove();
    }
    $('#wrapper .cookie-warning .submit').click(function (e) {
      e.preventDefault();
      window.DGS.Cookies.getInstance().createCookie("cookie-warning", 1, 365);
      tween.kill();
      tween = TweenMax.to($('#wrapper'), .3, {
        css: {
          marginTop: 0,
          marginBottom: 0
        },
        delay: 0
      });
      cookieWarning.remove();
      window.DGS.Event.getInstance().trigger('cookie-warning-submit');
    });
    window.DGS.Resizer.getInstance().register(function () {
      var isEmpty = cookieWarning.find(".component-content").children().length,
        newMargin = cookieWarning.outerHeight();
      if (window.matchMedia("(max-width: 1024px)").matches) {
        if (isEmpty) {
          tween = TweenMax.to($('#wrapper'), .3, {
            css: {
              marginBottom: newMargin,
              marginTop: 0
            },
            delay: 1.5
          });
        } else {
          tween = TweenMax.to($('#wrapper'), .3, {
            css: {
              marginTop: 0,
              marginBottom: 0
            },
            delay: 0
          });
        }
      } else if (isEmpty) {
        tween = TweenMax.to($('#wrapper'), .3, {
          css: {
            marginTop: newMargin,
            marginBottom: 0
          },
          delay: 1.5
        });
      } else {
        tween = TweenMax.to($('#wrapper'), .3, {
          css: {
            marginTop: 0,
            marginBottom: 0
          },
          delay: 0
        });
      }
    });
  });
})(jQuery);