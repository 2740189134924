var flexSpotExpandable = document.querySelectorAll(".flex-spot.expandable");
var handleExpand = function handleExpand(itemsToExpand) {
  itemsToExpand.forEach(function (item) {
    return item.classList.toggle("expanded");
  });
};
if (flexSpotExpandable) {
  flexSpotExpandable.forEach(function (flexSpot) {
    var expandBtn = flexSpot.querySelector(".element__item__link");
    var itemsToExpand = flexSpot.querySelectorAll(".element__item");
    expandBtn.addEventListener("click", function () {
      return handleExpand(itemsToExpand);
    });
  });
}