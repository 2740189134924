function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    var centerLocatorComponent = $('.distributor-locator');
    var isFromSearch = false;
    var initRun = true;
    if (centerLocatorComponent != 'undefined' && centerLocatorComponent.length > 0) {
      var initializeMap = function initializeMap() {
        getCenters();
      };
      var getCenters = function getCenters() {
        centerLocatorComponent.find('.search-field').val('');
        url = baseUrl + '/getdistributors/_';
        ACTION.push(35 + ' getallcenters, url: ' + url);
        clearMarkersLocal();
      };
      var getCentersByPostalCode = function getCentersByPostalCode(search) {
        url = baseUrl + '/getdistributorbysearch/_/' + search;
        var searchedLat;
        var searchedLng;
        var searchedPost;
        $.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          success: function success(data, textStatus, xhr) {
            resetMarkers(0);
            clearInfoWindow();
            var noResultsMsg = centerLocatorComponent.find('.no-results-message');
            //$('.single-center').closest('.gm-style-iw').parent().hide();
            //console.log('lat up ' + data.Distributors[0].Coordinate.Latitude);
            //console.log('long up ' + data.Distributors[0].Coordinate.Longitude);

            if (data.Distributors[0].Coordinate == null) {
              showHideClass(false, noResultsMsg);
              if (noResultsMsg != null) noResultsMsg.html(centerLocatorComponent.find('.search').data('nosearchresults'));
            } else {
              showHideClass(true, noResultsMsg);
              var latLng = new google.maps.LatLng(data.Distributors[0].Coordinate.Latitude, data.Distributors[0].Coordinate.Longitude);
              map.panTo(latLng);
              map.setZoom(13);

              //show inforWindow on success
              var center = data.Distributors[0];
              var singleCenterTemplate = $("#single-center-template").html();
              var centerDiv = singleCenterTemplate.format(center.Name, center.Address, center.EmailAddress, center.FaxNumber, center.PhoneNumber, center.MobileNumber, center.Id);
              centerDiv = validateCenterDiv(centerDiv, center.EmailAddress, center.FaxNumber, center.MobileNumber, center.PhoneNumber);
              var infoWindow = new google.maps.InfoWindow({
                content: centerDiv,
                id: center.Id
              });
              if (openInfoWindows.length > 0) {
                $.each(openInfoWindows, function (index, openInfoWindow) {
                  if (infoWindow.content !== openInfoWindow.content) {
                    openInfoWindow.close();
                  } else if (infoWindow.title === openInfoWindow.title) {
                    return;
                  }
                });
              }
              var theMarker = '';
              jQuery.grep(markersArray, function (n, i) {
                if (n.id == center.Id) {
                  theMarker = markersArray[i];
                  return;
                }
              });
              infoWindow.open(map, theMarker);
              window.DGS.Event.getInstance().trigger(window.DGS.Events.DISTRIBUTOR_LOCATOR_OPEN_INFO_WINDOW);
              openInfoWindows.push(infoWindow);
              if (selectedMarker) {
                selectedMarker.setIcon(defaultSettings.markers.defaultMarker);
              }
              theMarker.setIcon(defaultSettings.markers.selectedMarker);
              selectedMarker = theMarker;
              google.maps.event.addListener(infoWindow, 'closeclick', function (e) {
                resetMarkers(0);
                clearInfoWindow();
              });
              resetMarkers(data.Distributors[0].Id);
              if ($(document).width() > 768 && $(document).width() < 1024) {
                map.panBy(-200, -100);
              }
            }
          }
        });
      };
      var validateCenterDiv = function validateCenterDiv(centerDiv, emailAddress, faxNumber, mobileNumber, phoneNumber) {
        var $centerDiv = $(centerDiv);
        var emailDiv = $centerDiv.find('.center-email');
        var faxDiv = $centerDiv.find('.center-fax');
        var phoneDiv = $centerDiv.find('.center-phone');
        var mobileDiv = $centerDiv.find('.center-mobilephone');
        if (emailAddress == '') showHideClass(true, emailDiv);
        if (faxNumber == '') showHideClass(true, faxDiv);
        if (mobileNumber == '') showHideClass(true, mobileDiv);
        if (phoneNumber == '') showHideClass(true, phoneDiv);
        return $centerDiv[0];
      };
      var getAllCenters = function getAllCenters(url, singelton, mapUpDate) {
        singelton = singelton || false;
        mapUpDate = mapUpDate || false;
        url = url || false;
        ACTION.push(72 + ' getAllCenters, url: ' + url + ', singelton: ' + singelton + ', mapUpDate: ' + mapUpDate);
        centers = null;
        setting = null;
        searchAktiv = false;
        var noResultsMsg = centerLocatorComponent.find('.no-results-message');
        $.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          success: function success(data, textStatus, xhr) {
            // Updates the map settings, look markers and so on...
            if (!mapSetUpdated && data.Settings.MapConfiguration != '') {
              userLocation.unit = data.Settings.Unit;
              var mapSet = JSON.parse(data.Settings.MapConfiguration);
              defaultSettings = $.extend({}, defaultSettings, mapSet);
              mapSetUpdated = true;
              mapStyle = {
                gridSize: data.Settings.ClusterGridSize,
                maxZoom: data.Settings.ClusterMaxZoom,
                styles: [{
                  textColor: 'white',
                  height: 45,
                  url: defaultSettings.clusters.clusterSize1,
                  width: 46
                }, {
                  height: 49,
                  textColor: 'white',
                  url: defaultSettings.clusters.clusterSize2,
                  width: 50
                }, {
                  height: 50,
                  textColor: 'white',
                  url: defaultSettings.clusters.clusterSize3,
                  width: 50
                }, {
                  height: 70,
                  textColor: 'white',
                  url: defaultSettings.clusters.clusterSize4,
                  width: 70
                }, {
                  height: 90,
                  textColor: 'white',
                  url: defaultSettings.clusters.clusterSize5,
                  width: 90
                }]
              };
            }
            // Updates the selected center data...
            if (!singelton) {
              centers = data.Distributors;
              setting = data.Settings;
              if (centers == null) {
                showHideClass(false, noResultsMsg);
                if (noResultsMsg != null) noResultsMsg.html(centerLocatorComponent.find('.search').data('nosearchresults'));
              } else {
                showHideClass(true, noResultsMsg);
              }
              ACTION.push(121 + ' Updates the selected center data ', setting);
              if (mapUpDate) {
                ACTION.push(123 + ' set bounds ');
                if (setting.Bounds.ShowMap || setting.ShowMap) {
                  if (isFromSearch && setting.Query != null) {
                    var strLatitude = setting.Query.split(',')[0];
                    var strLongitude = setting.Query.split(',')[1];
                    var pos = new google.maps.LatLng(strLatitude, strLongitude);
                    map.setCenter(pos);
                    if ($(document).width() > 768 && $(document).width() < 1024) {
                      map.panBy(200, -100);
                    }
                  } else {
                    var usrLatitude = $thisSpot.data('userlat');
                    var usrLongitude = $thisSpot.data('userlong');
                    if (usrLatitude.length < 1) {
                      usrLatitude = 0;
                    }
                    if (usrLongitude.length < 1) {
                      usrLongitude = 0;
                    }
                    if (usrLatitude != 0 || usrLongitude != 0) {
                      var pos = new google.maps.LatLng(usrLatitude, usrLongitude);
                      drawMap(setting.ZoomLevel, pos);
                    } else {
                      var pos = new google.maps.LatLng(setting.Bounds.SouthWest.Latitude, setting.Bounds.NorthEast.Longitude),
                        swb1 = setting.Bounds.SouthWest.Latitude,
                        swb2 = setting.Bounds.SouthWest.Longitude,
                        neb1 = setting.Bounds.NorthEast.Latitude,
                        neb2 = setting.Bounds.NorthEast.Longitude,
                        southWest = new google.maps.LatLng(swb1, swb2),
                        northEast = new google.maps.LatLng(neb1, neb2),
                        bounds = new google.maps.LatLngBounds(southWest, northEast);
                      ACTION.push(133 + ' drawMap ');
                      drawMap(setting.ZoomLevel, pos);
                      map.fitBounds(bounds);
                    }
                  }
                  mapUpDate = false;
                }
              }
              setUpCenterList(mapUpDate);
            }
          },
          error: function error(xhr, textStatus, errorThrown) {}
        });
      };
      var clearInfoWindow = function clearInfoWindow() {
        $('.single-center').closest('.gm-style-iw').parent().hide();
      };
      var showHideClass = function showHideClass(status, div) {
        status = status || false;
        if (status) {
          div.hide();
        } else {
          div.show();
        }
      };
      var setUpCenterList = function setUpCenterList(mapUpDate) {
        mapUpDate = mapUpDate || false;
        ACTION.push(166 + ' ! setUpCenterList ! mapUpDate:', mapUpDate, ' numberOfElements : ', numberOfElements);
        if (centers != null) {
          numberOfElements = centers.length;
          if (numberOfElements == 0) numberOfElements = setting.ListCount;
          ACTION.push(170 + ' numberOfElements : ' + numberOfElements);
          for (var i = 0; i < numberOfElements; i++) {
            var center = centers[i];
            if (setting.ShowMap && firstRun) {
              var singleCenterTemplate = $("#single-center-template").html();
              var centerDiv = singleCenterTemplate.format(center.Name, center.Address, center.EmailAddress, center.FaxNumber, center.PhoneNumber, center.MobileNumber, center.Id);
              centerDiv = validateCenterDiv(centerDiv, center.EmailAddress, center.FaxNumber, center.MobileNumber, center.PhoneNumber);
              addMarkers(center.Coordinate.Latitude, center.Coordinate.Longitude, center.Name, center.Id, centerDiv);
            } else {
              addMarkers(center.Coordinate.Latitude, center.Coordinate.Longitude, center.Name, center.Id);
            }
          }
          if (mc != undefined && mc != null) mc.clearMarkers();
          mc = new MarkerClusterer(map, markersArray, mapStyle);
          ACTION.push(181 + ' mapStyle : ', mapStyle);
          if (firstRun) {
            $('.search-field', $thisSpot).val();
            $('.search button', $thisSpot).on('click', function (e) {
              e.preventDefault();
              resetList();
            });
            $('.search', $thisSpot).on('keypress', function (e) {
              if (e.keyCode == 13) {
                e.preventDefault();
                resetList();
              }
            });
            var postal_cookie = window.DGS.Cookies.getInstance().readCookie("postal_code");
            if (postal_cookie !== null) {
              $('.search-field', $thisSpot).val(postal_cookie);
              resetList();
            }
            $('.search', $thisSpot).submit(function (e) {
              e.preventDefault();
              resetList();
            });
            $('.zoom.control > .zoom-in, .zoom.control > .zoom-out', $thisSpot).on('click', function () {
              var zno = 16;
              if ($(this).hasClass('zoom-in')) {
                zno = Math.round(map.getZoom() + 1);
              } else {
                zno = Math.round(map.getZoom() - 1);
                if (zno < 2) {
                  return;
                }
                if (zno < 4) {
                  $('.single-center').closest('.gm-style-iw').parent().hide();
                }
              }
              map.setZoom(zno);
              var center = openInfoWindows[0].getPosition();
              map.setCenter(center);
              map.panBy(0, -200);
            });
            firstRun = false;
            google.maps.event.addListener(map, 'idle', function () {
              if (!firstRun) {
                var newCenter = map.getCenter(),
                  newLat = newCenter.lat(),
                  newLng = newCenter.lng();
                ACTION.push(226 + ' !firstRun calls getAllCCenters, newCenter: ' + newCenter + ', singelton: true');
                while (newLat > 180) newLat -= 180;
                while (newLat < -180) newLat += 180;
                while (newLng > 180) newLng -= 180;
                while (newLng < -180) newLng += 180;
                url = baseUrl + '/getcentersbycoordinate/' + getMarket() + '/' + newLat + "/" + newLng;
                if (newLat != oldLat && newLng != oldLng) {
                  oldLat = newLat;
                  oldLng = newLng;
                  ACTION.push(235 + ' newLat : ' + url);
                }
              }
              ACTION.push(248 + ' getAllCenters, calPosition: ' + newCenter);
              window.DGS.Event.getInstance().trigger(window.DGS.Events.DISTRIBUTOR_LOCATOR_OPEN_INFO_WINDOW);
            });
          }
          ;
          $('.center-cta.button', $thisSpot).on('click', function (e) {
            e.preventDefault();
            window.open("https://maps.google.com?daddr=" + $(this).attr('id'), "_blank", "toolbar=no, scrollbars=no, resizable=yes, top=0, left=0, width=100%");
          });
          if (infoWindows.length > 0) $('.center-content' + '#' + infoWindowID).addClass('open');
          $('.center-cta.button', $thisSpot).on('click', function (e) {
            e.preventDefault();
            window.open("https://maps.google.com?daddr=" + $(this).attr('id'), "_blank", "toolbar=no, scrollbars=no, resizable=yes, top=0, left=0, width=100%");
          });
        }
      }; //Get Center by Search
      var resetList = function resetList() {
        var query = $('.search-field', $thisSpot).val();
        if (markersArray.length > 0) {
          markersArray[0].setMap(null);
          //markersArray = [];
        }
        if (query != '' && query != null & query != undefined) {
          clearInfoWindow();
          getCentersByPostalCode(query);
        }
      };
      var addMarkers = function addMarkers(latitude, longitude, name, guid, centerDiv) {
        var pos = new google.maps.LatLng(latitude, longitude);
        var infoWindow = new google.maps.InfoWindow({
          content: centerDiv,
          id: guid
        });
        var marker = new google.maps.Marker({
          position: pos,
          map: map,
          title: name,
          icon: defaultSettings.markers.defaultMarker,
          id: guid
        });
        markersArray.push(marker);
        google.maps.event.addListener(marker, 'click', function () {
          if (openInfoWindows.length > 0) {
            $.each(openInfoWindows, function (index, openInfoWindow) {
              if (infoWindow.content !== openInfoWindow.content) {
                openInfoWindow.close();
              } else if (infoWindow.title === openInfoWindow.title) {
                return;
              }
            });
          }
          infoWindow.open(map, this);
          openInfoWindows.push(infoWindow);
          if (selectedMarker) {
            selectedMarker.setIcon(defaultSettings.markers.defaultMarker);
          }
          marker.setIcon(defaultSettings.markers.selectedMarker);
          selectedMarker = marker;
          resetResult($(this));
          $('.single-center').closest('.gm-style-iw').parent().hide();
          map.panTo(this.getPosition());
          map.setZoom(13);
          $('#' + guid).closest('.gm-style-iw').parent().show();
          window.DGS.Event.getInstance().trigger(window.DGS.Events.DISTRIBUTOR_LOCATOR_OPEN_INFO_WINDOW);
          //$('#' + guid).append("<div class=\"gm-close-btn\" onclick=\" $('.single-center').closest('.gm-style-iw').parent().hide(); \"></div>");
          if ($(document).width() > 768 && $(document).width() < 1024) {
            map.panBy(-200, -100);
          }
        });
        google.maps.event.addListener(infoWindow, 'closeclick', function (e) {
          resetMarkers(0);
          clearInfoWindow();
        });
      };
      var resetResult = function resetResult(that) {
        if (that.hasClass('center-content')) {
          if (that.hasClass('open')) {
            that.removeClass('open');
            //if ($thisSpot.hasClass('mobile-view')) {
            //    that.find('.single-center').hide();
            //    console.log('HVAD NU? :', $('.single-center'));
            //}
            resetMarkers(0);
            clearInfoWindow();
          } else {
            var mID = that.attr('id');
            resetMarkers(mID);
          }
        } else {
          var mID = that.attr('id');
          resetMarkers(mID);
        }
      };
      var resetMarkers = function resetMarkers(id) {
        for (var a = 0; a < markersArray.length; a++) {
          markersArray[a].setIcon(defaultSettings.markers.defaultMarker);
          if (markersArray[a].id == id) markersArray[a].setIcon(defaultSettings.markers.selectedMarker);
        }
        //setDetails(id);
      };
      var clearMarkersLocal = function clearMarkersLocal() {
        for (var i = 0; i < markersArray.length; i++) {
          markersArray[i].setMap(null);
        }
        markersArray = [];
        ACTION.push(345 + ' HHHHHHHHHHHHHHHHHHH clearMarkersLocal markersArray: ', markersArray);
        markersArray = new Array();
        firstRun = true;
        getAllCenters(url, false, true);
        if (mc != undefined) mc.clearMarkers();
      };
      var drawMap = function drawMap(zoom, pos) {
        zoom = zoom || 10;
        var isDraggable = $(document).width() > 480 ? true : false;
        ACTION.push(353 + ' DRAWMAP, zoom: ' + zoom + ' pos: ' + pos + ' map: ' + map);
        if (map == undefined) {
          var mapOptions = {
            draggable: isDraggable,
            panControl: true,
            scrollwheel: false,
            zoom: 10,
            zoomControl: true,
            center: pos,
            disableDefaultUI: true,
            key: 'AIzaSyDLUitCuNZK9T-RVR9cjuh9Js6Q-vcv-BU',
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_TOP,
              style: google.maps.ZoomControlStyle.SMALL
            }
          };
          map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
          map.set('styles', defaultSettings.googlemap);
        }
      };
      var getMarket = function getMarket() {
        return $thisSpot.data('configuration');
      }; //Method to get the single center data when the individual center is clicked
      var setDetails = function setDetails(guid) {
        $('#' + guid).show();
      };
      /* Add format as function to strings - used for template */
      String.prototype.format = function () {
        var args = arguments;
        return this.replace(/{(\d+)}/g, function (match, number) {
          return typeof args[number] != 'undefined' ? args[number] : match;
        });
      };
      var consoleON = false,
        firstRun = true,
        country = 'us.json',
        testData = [country, 'ip.json', 'manhatten.json'],
        centers = null,
        setting = null,
        oldLng = '',
        oldLat = oldLng,
        url = oldLat,
        locationCheck = false;
      var actionsListen = function actionsListen() {
        var ACTION = [];
        ACTION.push = function () {
          if (consoleON) return Array.prototype.push.apply(this, arguments);
        };
        return ACTION;
      };
      var ACTION = new actionsListen();
      var defaultSettings = {
        markers: {
          defaultMarker: '~/media/oticon/main/mockup/oticon-marker.png',
          selectedMarker: '~/media/oticon/main/mockup/oticon-marker-aktiv.png',
          geoMarker: '~/media/oticon/main/mockup/oticon-marker-home.png'
        },
        clusters: {
          clusterSize1: '~/media/oticon/main/mockup/m1.png',
          clusterSize2: '~/media/oticon/main/mockup/m2.png',
          clusterSize3: '~/media/oticon/main/mockup/m3.png',
          clusterSize4: '~/media/oticon/main/mockup/m4.png',
          clusterSize5: '~/media/oticon/main/mockup/m5.png'
        },
        googlemap: [{
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{
            color: '#FFFFFF'
          }, {
            weight: 1
          }]
        }, {
          featureType: 'road.highway',
          elementType: 'labels',
          stylers: [{
            visibility: 'off'
          }]
        }]
      };
      var map,
        searchAktiv = false,
        infoWindows = [],
        openInfoWindows = [],
        infoWindowID = 0,
        baseUrl = '/webservices/distributor.svc',
        numberOfElements = 0,
        markersArray = [],
        selectedMarker = false,
        noScrollWheel = false,
        mapSetUpdated = false,
        userLocation = {
          ip: true,
          lat: 0,
          lng: 0,
          unit: 'KM'
        },
        $thisSpot = $('.distributor-locator'),
        //$result = $('.result', $thisSpot),
        mc;
      var mapStyle = {};
      //if (document.location.hostname == "localhost") baseUrl = 'http://oticon.local.cas.dgs.com/webservices/centerlocator.svc';

      if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) noScrollWheel = true;
      if (noScrollWheel || window.matchMedia("(max-width: 800px)").matches) {
        //sin$result.addClass('mobile-view');
        $thisSpot.addClass('mobile-view');
      }
      if ((typeof google === "undefined" ? "undefined" : _typeof(google)) === 'object' && _typeof(google.maps) === 'object') {
        initializeMap();
      }
    }
  });

  // Add a new event to the DGS.Events list
  window.DGS.Events = window.DGS.Events || {};
  $.extend(window.DGS.Events, {
    DISTRIBUTOR_LOCATOR_OPEN_INFO_WINDOW: "distributorLocatorOpenInfoWindow"
  });
})(jQuery);