(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    // comparison table
    $(".comparison-table ul").on("click", "li", function () {
      var pos = $(this).index() + 2;
      $("tr").find("td:not(:eq(0))").hide();
      $("td:nth-child(" + pos + ")").css("display", "table-cell");
      $("tr").find("th:not(:eq(0))").hide();
      $("li").removeClass("active");
      $(this).addClass("active");
    });
  });
})(jQuery);