(function ($) {
  "use strict";

  window.DGS.OnContentLoaded.getInstance().register(function () {
    $('.footer-fix > .component-content > ul > li').height($('.footer-fix > .component-content > ul').height());

    // column splitter 
    $('.component.in-grid-column-splitter').each(function () {
      $(this).closest('.row').addClass('in-grid-column-splitter');
    });
  });
  window.DGS.Resizer.getInstance().register(function () {
    if ($('body').width() > 1023) {
      $('#footer .footer-fix ul > li').height('auto');
    }
  });
})(jQuery);