(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('.download-center').each(function () {
      window.DGS.Overflow.getInstance().register($('.download-center'), $('.nav.top-menu li:last-child', $('.download-center')), function () {
        var $first = $('.nav.top-menu li:last-child', $('.download-center')).offset().top,
          $second = $('.nav.top-menu li:first-child', $('.download-center')).offset().top;
        if ($first != $second) {
          return true;
        } else {
          return false;
        }
      });
    });
    var mainID = $('.download-center .component-content > div').attr('data-id'),
      assetPath = '/webservices/downloadcenter.svc/GetAssets/',
      topicTemplate = $('#topic-template').html(),
      pdfTemplate = $('#asset-template-pdf').html(),
      videoTemplate = $('#asset-template-video').html(),
      mixedTemplate = $('#asset-template-mixed').html(),
      embedTemplate = $('#asset-template-embed').html(),
      language = $('html').attr('lang'),
      firstRun = true;
    $('.download-center').each(function () {
      var $this = $(this),
        $that = $this,
        $content = $('.download-center-content', $this),
        $leftMenu = $('.left-menu li', $this),
        $topMenu = $('.top-menu li', $this),
        aktivTop = checkActive($topMenu, 1, $this),
        aktivLeft = checkActive($leftMenu, 1, $this),
        aktiveDataSheet = $('.top-menu', $this).attr('data-id'),
        aktivePrimaryId = setActive($topMenu, aktivTop),
        aktiveSecondary = setActive($leftMenu, aktivLeft),
        applyDownloadTracking = function applyDownloadTracking() {
          $('.download-pdf a', $this).click(function () {
            var pdfName = $(this).text();
            window.DGS.Event.getInstance().trigger('trackDownload', 'pdf', pdfName);
          });
        };
      applyDownloadTracking();
      if ($this.hasClass('non-active')) {
        aktivePrimaryId = $('.top-menu li:nth-child(1)').attr('data-id').replace('{', '').replace('}', '');
        aktiveSecondary = $('.left-menu li:nth-child(1)').attr('data-id').replace('{', '').replace('}', '');
        getData(aktiveDataSheet + '/' + aktivePrimaryId + '/' + aktiveSecondary, true, $topMenu);
      }
      $('.thumb-wrapper.light-gallery', $this).lightGallery({
        counter: false,
        zoom: false,
        fullScreen: false,
        download: false
      });
      $('.download-video .thumb-wrapper a', $this).each(function () {
        var $this = $(this),
          src = $this.attr('href'),
          vID = src != null ? src.substr(src.indexOf('v=') + 2) : null,
          thumbPath = vID != null ? 'http://img.youtube.com/vi/' + vID + '/0.jpg' : '';
        $('img', $this).attr('src', thumbPath);
      });
      $('.left-menu li', $this).click(function (event) {
        event.preventDefault();
        if ($this.hasClass('content-overflow') && !$this.hasClass('sub-active') || !$this.hasClass('content-overflow')) {
          aktiveSecondary = $(this).attr('data-id').replace('{', '').replace('}', '');
          resetActive($leftMenu, $(this));
          $this.addClass('sub-active');
          getData(aktiveDataSheet + '/' + aktivePrimaryId + '/' + aktiveSecondary, true);
        } else if ($this.hasClass('content-overflow') && $this.hasClass('sub-active')) {
          $this.removeClass('sub-active');
        }
        // Scrolling to top on click can be disabled by adding the style below to the download-center component
        var noScrollToTop = $('.download-center').hasClass('download-center-no-scroll-to-top');
        if (!noScrollToTop) {
          $("html, body").animate({
            scrollTop: 0
          }, 600);
        }
      });
      $('.left-menu a', $this).each(function () {
        $(this).click(function (event) {
          event.preventDefault();
        });
      });
      decorateThumbnailItems();
      $('.top-menu li', $this).each(function () {
        $(this).click(function (event) {
          event.preventDefault();
          if ($this.hasClass('content-overflow') && !$this.hasClass('top-active') || !$this.hasClass('content-overflow')) {
            aktivePrimaryId = $(this).attr('data-id').replace('{', '').replace('}', '');
            resetActive($topMenu, $(this));
            $this.addClass('top-active').removeClass('non-active');
            getData(aktiveDataSheet + '/' + aktivePrimaryId + '/' + aktiveSecondary, true);
            $('.left-menu li', $this).each(function () {
              var secondary = $(this).attr('data-id').replace('{', '').replace('}', '');
              $('a', $(this)).attr('href', window.location.pathname + "?primaryID=" + aktivePrimaryId + "&secondaryID=" + secondary);
            });
          } else if ($this.hasClass('content-overflow') && $this.hasClass('top-active')) {
            $this.removeClass('top-active').addClass('non-active');
            $(this).removeClass('active');
          }
        });
      });
      $('.top-menu a', $this).each(function () {
        $(this).click(function (event) {
          event.preventDefault();
        });
      });
      $content.find('a').attr('target', '_blank');
      function getData(url) {
        url = assetPath + url + "/" + language;
        $.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          success: function success(data, textStatus, xhr) {
            var newHtml = '';
            $.each(data.Topics, function (index, topic) {
              var newData = '',
                c = 0;
              $.each(topic.Assets, function (a, b) {
                switch (b.Type.toLowerCase()) {
                  case 'pdf':
                    newData += pdfTemplate.format(b.AssetUri, b.Date, b.ImageUri, b.Name, b.SortDate, b.Type, b.Visible, c % 2 ? 'odd' : 'even', "_blank");
                    c++;
                    break;
                  case 'mixed':
                    newData += mixedTemplate.format(b.AssetUri, b.Date, b.ImageUri, b.Name, b.SortDate, b.Type, b.Visible, c % 2 ? 'odd' : 'even');
                    c++;
                    break;
                  case 'video':
                    var img = b.ImageUri;
                    newData += videoTemplate.format(b.AssetUri, b.Name, img, c % 2 ? 'odd' : 'even');
                    c++;
                    break;
                  case 'embed':
                    var img = b.ImageUri;
                    newData += embedTemplate.format(b.AssetUri, b.Name, img, c % 2 ? 'odd' : 'even');
                    c++;
                    break;
                  default:
                    break;
                }
              });
              var theData = topicTemplate.format(topic.Name, newData);
              newHtml += theData;
            });
            $content.html(newHtml);
            $('.thumb-wrapper.light-gallery', $this).lightGallery({
              counter: false,
              zoom: false,
              fullScreen: false,
              download: false
            });
            decorateThumbnailItems();
            //window.DGS.Event.getInstance().trigger('video-spot-reinit');
            window.DGS.Event.getInstance().trigger('download-center-tab-init');
            applyDownloadTracking();
          },
          error: function error(xhr, textStatus, errorThrown) {
            console.log('ajax error ', url);
          }
        });
      }
      function checkActive(menu, active, $spot) {
        var showFirst = menu.parent().parent().hasClass('top-menu'),
          mobileMOde = $('.download-center').hasClass('content-overflow');
        active = active || 1;
        menu.each(function (index) {
          if ($(this).hasClass('active')) active = index + 1;
        });
        // For the hidden top-menu, it should activate at first init
        if (menu.parent().parent().height() > 0) {
          if (showFirst) {
            if (!mobileMOde) {
              $spot.addClass('top-menu').removeClass('non-active');
            } else {
              $spot.addClass('non-active');
            }
          }
        } else {
          if (showFirst) {
            if (!mobileMOde) {
              $spot.addClass('top-menu');
            }
            $spot.addClass('non-active');
          }
        }
        if (firstRun) {
          firstRun = false;
          if (mobileMOde) {
            $('.component-content .top-menu ul li.active', $spot).removeClass('active');
          }
        }
        return active;
      }
      ;
      function setActive(menu, ac) {
        if (menu.parent().parent().hasClass('top-menu')) {
          ac = ac || aktivTop;
        } else {
          ac = ac || aktivLeft;
        }
        ac = $(menu[ac - 1]).attr('data-id').replace('{', '').replace('}', '');
        return ac;
      }
      ;
      function resetActive(menu, me) {
        menu.each(function (index) {
          $(this).removeClass('active');
        });
        me.addClass('active');
      }
    });
    window.DGS.EqualHeight.getInstance().register($('.download-center-content .embed-video'), true);
    function addPreviewLogic($item, imgSrc) {
      $item.hover(function (e) {
        $('.download-center-item-thumbnail-preview').remove();
        $("body").append('<div class="download-center-item-thumbnail-preview"><img src="' + imgSrc + '" /></div>');
        $('.download-center-item-thumbnail-preview').css("top", e.pageY + 10 + "px").css("left", e.pageX + 10 + "px").fadeIn("fast");
      });
      $item.mousemove(function (e) {
        $('.download-center-item-thumbnail-preview').css("top", e.pageY + 10 + "px").css("left", e.pageX + 10 + "px");
      });
      $item.mouseleave(function () {
        $('.download-center-item-thumbnail-preview').remove();
      });
    }
    function decorateThumbnailItems() {
      $('.download-pdf > a > img').each(function () {
        var $this = $(this),
          $a = $this.parent(),
          $item = $a.parent(),
          src = $this.attr('src');
        if (src && src != undefined && src != "undefined" && src != null && src != "" && src != "null") {
          $item.addClass('has-thumbnail');
          $a.before($('<div style="background-image:url(\'' + src + '\');" class="item-thumbnail"></div>'));
          addPreviewLogic($('.item-thumbnail', $item), src);
        } else {
          $this.remove();
        }
      });
      //function for overlay when thumbnail is clicked - Start
      $(".item-thumbnail").on('touchend', function (e) {
        if (!$('.overlay-item-thumbnail').length) {
          $('body').append('<div class="overlay-item-thumbnail"> </div>');
        }
      }).keyup(function (e) {
        if (e.which == 27) {
          $('.overlay-item-thumbnail', '.download-center-item-thumbnail-preview').remove();
        }
      }).blur(function (e) {
        $('.overlay-item-thumbnail', '.download-center-item-thumbnail-preview').remove();
      });
      $('body').on('touchend', function (e) {
        if (!$(e.target).is('.item-thumbnail', '.download-center-item-thumbnail-preview', '.overlay-item-thumbnail')) {
          $('.download-center-item-thumbnail-preview').remove();
          $(".overlay-item-thumbnail").remove();
        }
      });
      //function for overlay when thumbnail is clicked - stop
    }
  });
})(jQuery);