(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $(document).click(function (event) {
      if (!$(event.target).closest('.extranet-summary').length) {
        if ($('.extranet-summary').is(":visible")) {
          $('.success-message').hide();
        }
      }
    });
    $('.extranet-request-account').each(function () {
      var $this = $(this),
        $countryInput = $('input#main_0_CountryTextBox', $this);
      $countryInput.autocomplete({
        source: function source(request, response) {
          $.ajax({
            url: '/webservices/autocomplete.svc/search/_/' + $countryInput.val(),
            type: 'GET',
            dataType: 'json',
            success: function success(data) {
              response(data.data);
            },
            error: function error(xhr, textStatus, errorThrown) {
              console.log('error extranet-request-account ' + textStatus);
            }
          });
        },
        open: function open() {
          $("input#main_0_CountryTextBox", $this).autocomplete("widget").css("width", $countryInput.innerWidth());
        }
      }).autocomplete("widget").addClass("request-account-country");
    });
    $('.extranet-request-account').each(function () {
      $(".heading", $(this)).insertBefore($(".description"), $(this));
    });
  });
})(jQuery);