(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    var languageSpot = $('#header .component.language-selector-spot'),
      $ul = $('.component-content ul', languageSpot),
      tLength = $('li', $ul).length,
      urlLength = 0,
      tempThis,
      tempURL = '',
      theURL;
    if (tLength > 0) {
      theURL = document.location.href.replace('http://', '').replace('https://', '');
      $('li', $ul).each(function (index) {
        tempURL = $('a', $(this)).attr('href').replace('http://', '').replace('https://', '');
        if (tempURL.endsWith("/")) {
          tempURL = tempURL.slice(0, -1);
        }
        if (theURL.indexOf(tempURL) == 0 && tempURL.length > urlLength || theURL.indexOf('localhost:3000') === 0 && index === 0) {
          urlLength = tempURL.length;
          if (tempThis != undefined) tempThis.removeClass('active');
          tempThis = $(this);
          $(this).addClass('active');
        }
      });
    } else {
      languageSpot.css('display', 'none');
    }
  });
  $('.language-selector').click(function () {
    $(".language-selector-spot ul").toggleClass("open");
    $(".language-selector-spot ul li").toggleClass("hidden");
    $(".language-selector-spot").toggleClass("unhide");
  });
})(jQuery);