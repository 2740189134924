(function ($, DGS) {
  "use strict";

  DGS.OnLoad.getInstance().register(function () {
    $('.component.related-blogs').each(function () {
      var $this = $(this);
      var $relatedPosts = $('.component-content ul > li > div', $this);
      $relatedPosts.each(function (i, relatedPost) {
        var $link = $('.news-link .link', $(relatedPost)).last();
        $(relatedPost).click(function (e) {
          if (!$(e.target).hasClass('link')) {
            // jQuery click does not work on links
            $link[0].click();
          }
        });
      });
      setTimeout(function () {
        $this.addClass("component-ready animate");
      }, 500);
    });
  });
})(jQuery, window.DGS);