(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    if ($("#wrapper #content .component.online-booking").length) {
      // Getting current language
      var $theLang = $('html').attr('lang');

      //Autosuggest
      var $useAutoSuggest = $(".component.online-booking.auto-suggest").length ? true : false;
      var $autoComplete;
      var dictionary = {
        Booking: {
          SearchCenters: "Search for center",
          ClearSearch: "Clear"
        }
      };

      //Data
      var availability;
      var centerSelector = $('.component.online-booking .center-selector select');
      var onlineBooking = $('.component.online-booking');
      var selectedMonth;
      var unAvailableDates = [];
      var selectedProvider = null;
      var providerNames = [];
      var centerId;
      var when;
      var from;
      var to;
      var appointmentTypeId;

      //Flags
      var loadingData = false;
      var showTimespan = false;
      var noCurrentAvailablity = false;

      //Templates
      var appoinmentTypesTemplate = $('#appointment-types-template').html();
      var appointmentDateTemplate = $('#appointment-date-template').html();
      var appoinmentTypeTemplate = $('#appointment-type-template').html();
      var availibilityTemplate = $('#availability-template').html();
      var timeslotWithTimespanTemplate = $('#timeslot-show-timespan-template').html();
      var timeslotTemplate = $('#timeslot-template').html();
      var customerFormTemplate = $('#customer-form-template').html();
      var calendarTemplate = $('#calendar-template').html();
      var successTemplate = $('#booking-success-template').html();
      var errorTemplate = $('#booking-error-template').html();
      var centerAddressTemplate = $('#center-address-template').html();

      //Template wrappers
      var appointmentTypeContainer = $('.appointment-types-container');
      var timeslotContainer = $('.availability-container');
      var calendarContainer = $('.calendar-container');
      var formContainer = $('.form-container');
      var resultContainer = $('.result-container');
      var centerAddressContainer = $('.center-address-container');
      var noOfDaysToBeBlocked;

      //For GTM data push
      var centerName = null;
      var appointmentName = null;
      when = defaultDate();
      initialize();
    }
    function initialize() {
      initializeAutosuggest();
      initializeCenterSelector();
      initializeCalendar();
      initializeAppointmentTypes();
      initializeTimeslots();
      initializeForm();
      initializeProviderSelector();
      clearErrors();
      disableAppointmentTypes();
      setAppointmentTypes();
    }
    function setAppointmentTypes() {
      setBooking();
    }
    function setBooking() {
      centerId = centerSelector.val();
      var disablebooking = onlineBooking.hasClass('disable-booking-calendar') ? 1 : centerSelector.find(':selected').data('disablebooking');
      if (centerId == "0") {
        centerAddressContainer.hide();
        if (onlineBooking.hasClass('disable-booking-calendar')) {
          appointmentTypeContainer.addClass('two-step');
          calendarContainer.addClass('two-step');
          timeslotContainer.addClass('two-step');
          formContainer.removeClass('disabled');
        }
      }
      if (centerId != "0" && disablebooking != "1") {
        centerAddressContainer.show();
        setCenterAddress();
        getAppointmentTypes();
        disableTimeslots();
        formContainer.removeClass('two-step');
        appointmentTypeContainer.removeClass('two-step');
        calendarContainer.removeClass('two-step');
        timeslotContainer.removeClass('two-step');
        initializeAbove60Selector();
      } else if (centerId != "0" && disablebooking == "1") {
        selectedProvider = null;
        hideProviders();
        centerAddressContainer.show();
        setCenterAddress();
        disableAppointmentTypes();
        enableForm();
        formContainer.addClass('two-step');
        appointmentTypeContainer.addClass('two-step');
        calendarContainer.addClass('two-step');
        timeslotContainer.addClass('two-step');
        initializeAbove60Selector();
      }
    }
    function setCenterAddress() {
      var centerTitle = centerSelector.find(':selected').data('centertitle');
      var centerAddress = centerSelector.find(':selected').data('centeraddress');
      var centerCity = centerSelector.find(':selected').data('centercity');
      var centerRegion = centerSelector.find(':selected').data('centerregion');
      var centerPostalCode = centerSelector.find(':selected').data('centerpostalcode');
      var centerAddressTemplatewocity = $('#center-address-template').html().replace("{2},", "{2}");
      var centerAddressTemplateworegion = $('#center-address-template').html().replace("{3},", "{3}");
      var centerAddressTemplatewocityregion = $('#center-address-template').html().replace("{2}, {3},", "{2} {3}");
      if (centerCity == "") {
        if (centerRegion == "") {
          var centeraddressHtml = centerAddressTemplatewocityregion.format(centerTitle, centerAddress, centerCity, centerRegion, centerPostalCode);
        } else {
          var centeraddressHtml = centerAddressTemplatewocity.format(centerTitle, centerAddress, centerCity, centerRegion, centerPostalCode);
        }
      } else if (centerRegion == "") {
        var centeraddressHtml = centerAddressTemplateworegion.format(centerTitle, centerAddress, centerCity, centerRegion, centerPostalCode);
      } else {
        var centeraddressHtml = centerAddressTemplate.format(centerTitle, centerAddress, centerCity, centerRegion, centerPostalCode);
      }
      centerAddressContainer.html(centeraddressHtml);

      // GTM track of selected center
      centerName = centerTitle;
      window.DGS.Event.getInstance().trigger('trackOBSelectCenter', centerName);
    }

    //Autosuggest START
    function initializeAutosuggest() {
      if ($useAutoSuggest) {
        DGS.Dictionary.getInstance().getTexts(dictionary, function () {
          autosuggestAddInput();
          autosuggestInit(autosuggestBuildList());
          autosuggestAttachEvents();
        });
      }
    }
    function autosuggestAddInput() {
      $('<div class="ui-widget autocomplete"><input id="autocomplete"  placeholder="' + dictionary.Booking.SearchCenters + '"><span class="autocomplete-clear">' + dictionary.Booking.ClearSearch + '</span></div>').insertAfter(".booking-select-center");
      $('.component.online-booking .center-selector').addClass("center-selector--hide-elements");
      $autoComplete = $("#autocomplete");
    }
    function autosuggestBuildList() {
      var availiableCenters = [];
      $("option", centerSelector).each(function () {
        var searchableValue,
          title = $(this).data("centertitle"),
          id = $(this).val();
        if (title != undefined && title != "") {
          searchableValue = title;
        }
        if (searchableValue !== undefined && searchableValue !== "") {
          availiableCenters.push({
            value: searchableValue,
            id: id
          });
        }
      });
      return availiableCenters;
    }
    function autosuggestInit(source) {
      $autoComplete.autocomplete({
        minLength: 0,
        source: source,
        select: function select(event, ui) {
          $("#autocomplete").val(ui.item.value);
          centerSelector.val(ui.item.id);
          setBooking();
          return false;
        }
      }).autocomplete("widget").addClass("online-booking-result");

      //IE11 fix to prevent auto focus due to placeholder attr content
      $autoComplete.focus().blur();

      //Make result list same size as input element
      $autoComplete.autocomplete("instance")._resizeMenu = function () {
        var ul = this.menu.element;
        ul.innerWidth(this.element.innerWidth() - 2); //Manual adjust for border width
      };
    }
    function autosuggestAttachEvents() {
      //open list on focus
      $autoComplete.bind('focus', function () {
        if (this.value.length === 0) $(this).autocomplete("search");
      });

      //Clear input on click
      $(".autocomplete-clear").click(function () {
        $autoComplete.val("");
        centerSelector.val(0);
        setBooking();
      });
    }
    //Autosuggest END

    function initializeCenterSelector() {
      centerSelector.change(function (e) {
        e.preventDefault();
        setBooking();
      });
    }
    function initializeCalendar() {
      calendarContainer.html(calendarTemplate);
      var $datepickerLang = null;
      $('#datepicker').datepicker({
        altField: ".datepicker-input",
        onChangeMonthYear: function onChangeMonthYear(year, month) {
          selectedMonth = month + "-" + 1 + "-" + year;
          getTimeslots(false);
        },
        beforeShowDay: function beforeShowDay(date) {
          var stringDate = $.datepicker.formatDate('mm-dd-y', date);
          if (!loadingData && stringDate && availability) {
            var items = availability.filter(function (element) {
              return element.Date === stringDate;
            });
            if (items.length < 1) {
              return false;
            }
            return [unAvailableDates.indexOf(stringDate) == -1];
          }
          return false;
        },
        dateFormat: "mm-dd-yy",
        altFormat: "mm-dd-yy",
        inline: true,
        showOtherMonths: true,
        selectOtherMonths: true,
        firstDay: 1,
        minDate: 1,
        onSelect: function onSelect() {
          when = $('.datepicker-input').val();
          updateTimeslots();
          enableTimeslots();
          // GTM track of selected date
          window.DGS.Event.getInstance().trigger('trackOBSelectDate', centerName, appointmentName);
        }
      });
      var datepickerLang = $.datepicker.regional[$theLang.toLowerCase()];
      if (datepickerLang !== undefined) {
        $.datepicker.setDefaults(datepickerLang);
      }
    }
    function initializeForm() {
      formContainer.html(customerFormTemplate);
      $('#submit-booking-form').on("click", submitBooking);
    }
    function scrollPage($distance) {
      TweenLite.to(window, 1, {
        scrollTo: $distance,
        ease: Power2.easeInOut,
        delay: 0.2
      });
    }
    function enableAppointmentTypes() {
      appointmentTypeContainer.removeClass('disabled');
      scrollPage($(".center-selector").offset().top - parseInt($("#header").css('height'), 10) - 2);
    }
    function enableCalendar() {
      calendarContainer.removeClass('disabled');
    }
    function clearCalendarSelection() {
      var date = $('#datepicker').find('a.ui-state-default.ui-state-active');
      date.removeClass('ui-state-active');
    }
    function enableTimeslots() {
      timeslotContainer.removeClass('disabled');
      scrollPage($("#datepicker").offset().top);
    }
    function enableForm() {
      formContainer.removeClass('disabled');
      setTimeout(function () {
        scrollPage($("#form-container").offset().top - 110);
      }, 50);
    }
    function disableAppointmentTypes() {
      appointmentTypeContainer.addClass('disabled');
      disableCalendar();
    }
    function disableCalendar() {
      calendarContainer.addClass('disabled');
      disableTimeslots();
    }
    function disableTimeslots() {
      timeslotContainer.addClass('disabled');
      disableForm();
    }
    function disableForm() {
      formContainer.addClass('disabled');
    }
    function initializeAppointmentTypes() {
      var innerHtml = '';
      var html = appoinmentTypesTemplate.format(innerHtml);
      appointmentTypeContainer.html(html);
    }
    function initializeTimeslots() {
      var innerHtml = '';
      var html = availibilityTemplate.format(innerHtml);
      timeslotContainer.html(html);
    }
    function findUnAvailableDates() {
      providerNames = [];
      availability.forEach(function (element) {
        var free = false;
        element.TimeSlots.forEach(function (slot) {
          if (slot.hasOwnProperty('Free') && slot.Free === true) {
            // Old flow. Is not used anymore in the backend apparently.
            free = true;
          }
          if (slot.hasOwnProperty('FreeProviderNames') && slot.hasOwnProperty('BusyProviderNames') && slot.FreeProviderNames !== null && slot.BusyProviderNames !== null) {
            // Din Hoerelse
            free = free || slot.FreeProviderNames.length > 0;
            providerNames = providerNames.concat(slot.FreeProviderNames).filter(function (item, pos, self) {
              return self.indexOf(item) === pos;
            });
            providerNames = providerNames.concat(slot.BusyProviderNames).filter(function (item, pos, self) {
              return self.indexOf(item) === pos;
            });
          }
          if (slot.hasOwnProperty('FreeProviderNames') && slot.hasOwnProperty('BusyProviderNames') && slot.FreeProviderNames !== null && slot.BusyProviderNames !== null && slot.FreeProviderNames.length === 0 && slot.BusyProviderNames.length === 0 && slot.AlternativeText !== null) {
            // All other retail
            free = true;
          }
        });
        if (!free) {
          unAvailableDates.push(element.Date);
        }
      });
    }
    function fincUnavailableDatesForProvider() {
      unAvailableDates = [];
      availability.forEach(function (element) {
        var free = false;
        element.TimeSlots.forEach(function (slot) {
          if (selectedProvider === null) {
            free = free || slot.FreeProviderNames.length > 0;
          } else {
            free = free || slot.FreeProviderNames.indexOf(selectedProvider) !== -1;
          }
        });
        if (!free) {
          unAvailableDates.push(element.Date);
        }
      });
    }
    function initializeProviderSelector() {
      $('select.center-provider-list__select').change(function () {
        var $this = $(this),
          value = $this.val();
        selectedProvider = value !== "null" ? value : null;
        fincUnavailableDatesForProvider();
        $('#datepicker').datepicker("refresh");
        clearCalendarSelection();
        disableTimeslots();
      });
    }
    function initializeAbove60Selector() {
      if ($('.component.online-booking').hasClass('booking-form-above-60')) {
        $('.booking-form-age-above-60').removeClass('field-hide');
      }
      $("input[type=radio][name=AgeAbove60]").on('click', function () {
        getAppointmentTypes();
      });
    }
    function providersFound() {
      return providerNames.length > 1;
    }
    function displayProviders() {
      var $providerContainer = $('div.center-provider-list'),
        $providerSelect = $('select.center-provider-list__select'),
        $provider;
      $('option:not(:first-child)', $providerSelect).remove();
      providerNames.forEach(function (providerName) {
        $provider = $('<option>').attr('value', providerName).text(providerName.replace(/([^,]+), ([^,]+)/g, "$2 $1"));
        if (selectedProvider && providerName === selectedProvider) {
          $provider.attr('selected', 'selected');
        }
        $providerSelect.append($provider);
      });
      $providerContainer.show();
    }
    function hideProviders() {
      var $providerContainer = $('div.center-provider-list'),
        $providerSelect = $('select.center-provider-list__select option:not(:first-child)');
      selectedProvider = null;
      $providerContainer.hide();
      $providerSelect.remove();
    }
    function checkLastAvailability() {
      var currentDate = $.datepicker.formatDate('y-mm-dd', new Date());
      var availabilityCount = 0;
      availability.forEach(function (element) {
        var array = element.Date.split('-');
        var date = array[2] + '-' + array[0] + '-' + array[1];
        if (date > currentDate) {
          if (element.TimeSlots.length > 0) {
            availabilityCount++;
          }
        }
      });
      return availabilityCount;
    }
    function updateTimeslots() {
      var date = $('#datepicker').datepicker("getDate");
      var day;
      for (var i = 0; i < availability.length; i++) {
        var dateParts = availability[i].Date.split('-');
        var currentDate = new Date(dateParts[2], dateParts[0] - 1, dateParts[1]).getDate();
        if (currentDate === date.getDate()) {
          day = availability[i];
        }
      }
      var innerHtml = '';
      $.each(day.TimeSlots, function () {
        var isFree = this.hasOwnProperty('Free') && this.Free ||
        // Old flow. Not used in the backend anymore
        selectedProvider === null && this.FreeProviderNames.length === 0 && this.BusyProviderNames.length === 0 ||
        // Other retail
        selectedProvider === null && this.FreeProviderNames.length > 0 ||
        // Din Hoerelse. No specialist chosen
        selectedProvider !== null && this.FreeProviderNames.indexOf(selectedProvider) !== -1; //Din Hoerelse. Specialist chosen.
        var disabled = isFree ? '' : 'disabled';
        var disableClick = isFree ? '' : 'onclick="return false;"';
        if (this.AlternativeText) {
          innerHtml += timeslotWithTimespanTemplate.format(this.StartTime, disabled, this.EndTime, disableClick, this.AlternativeText);
        } else if (showTimespan === true) {
          innerHtml += timeslotWithTimespanTemplate.format(this.StartTime, disabled, this.EndTime, disableClick, this.StartTime + " - " + this.EndTime);
        } else {
          innerHtml += timeslotTemplate.format(this.StartTime, disabled, this.EndTime, disableClick, this.StartTime);
        }
      });
      var html = availibilityTemplate.format(innerHtml);
      timeslotContainer.html(html);
      $('#appointment-time-error').hide();
      $('.time-item').on('click', function (e) {
        e.preventDefault();
        if (!$(this).hasClass('disabled')) {
          $.each($('.time-item'), function () {
            if ($(this).hasClass('active')) {
              $(this).removeClass('active');
            }
          });
          $(this).addClass('active');
          from = $(this).data('from-time');
          to = $(this).data('to-time');
          enableForm();
          // GTM track of selected date
          window.DGS.Event.getInstance().trigger('trackOBSelectTime', centerName, appointmentName);
        }
      });
    }
    function getAppointmentTypes() {
      var isAbove60Visible = $('.component.online-booking').hasClass('booking-form-above-60');
      var above60Value = $('input[type=radio][name=AgeAbove60]:checked').val();
      $.ajax({
        url: isAbove60Visible ? '/webservices/booking.svc/appointmenttypesbyage/' + centerId + '/' + $theLang + '/' + above60Value : '/webservices/booking.svc/appointmenttypes/' + centerId + '/' + $theLang,
        type: 'GET',
        dataType: 'json',
        success: function success(data) {
          //console.log('The data : ', data);
          updateAppointmentTypes(data);
        },
        error: function error() {
          //console.log('ajax error ', url);
        }
      });
    }
    function updateAppointmentTypes(appointmentTypes) {
      if (appointmentTypes.length > 0) {
        var innerHtml = '';
        $.each(appointmentTypes, function () {
          innerHtml += appoinmentTypeTemplate.format(this.Title, this.Description, this.AppointmentId);
        });
        var html = appoinmentTypesTemplate.format(innerHtml);
        appointmentTypeContainer.html(html);
        $('#appointment-type-error').hide();
        enableAppointmentTypes();
        $('input[name=appointment-type]').change(function () {
          appointmentTypeId = $('input[name=appointment-type]:checked').val();
          getTimeslots(false);
          enableCalendar();
          disableTimeslots();
          getCheckedAppointmentName(appointmentTypes);
        });
        $('input[name=appointment-type]:first').prop("checked", true);
        appointmentTypeId = $('input[name=appointment-type]:checked').val();
        selectedProvider !== null ? getTimeslots(false) : getTimeslots(true);
        if (noCurrentAvailablity) {
          $('#datepicker').datepicker('setDate', '+1m');
        }
        if (noOfDaysToBeBlocked) {
          $('#datepicker').datepicker("option", "minDate", parseInt(noOfDaysToBeBlocked) + 1);
          clearCalendarSelection();
        }
        enableCalendar();
        getCheckedAppointmentName(appointmentTypes);
      } else {
        appointmentTypeContainer.html(appointmentDateTemplate);
        getTimeslots(false);
        if (noCurrentAvailablity) {
          $('#datepicker').datepicker('setDate', '+1m');
        }
        if (noOfDaysToBeBlocked) {
          $('#datepicker').datepicker("option", "minDate", parseInt(noOfDaysToBeBlocked) + 1);
          clearCalendarSelection();
        }
        enableCalendar();
        enableAppointmentTypes();
      }
    }
    function getCheckedAppointmentName(appointmentTypes) {
      $.each(appointmentTypes, function () {
        var checkedAppointment = $('input[name=appointment-type]:checked').val();
        if (checkedAppointment === this.AppointmentId) {
          appointmentName = this.Title;
        }
      });
    }
    function getTimeslots(resetProviders) {
      if (resetProviders !== false) hideProviders();
      if (!selectedMonth) {
        selectedMonth = defaultDate();
      }
      loadingData = true;
      $.ajax({
        url: '/webservices/booking.svc/availability/' + centerId + '/' + selectedMonth + '/' + appointmentTypeId + '/' + $theLang,
        type: 'GET',
        dataType: 'json',
        async: false,
        success: function success(data) {
          //console.log('The data : ', data);
          var jsonData = JSON.parse(data);
          showTimespan = jsonData.DisplayTimespan;
          availability = jsonData.Availability;
          noOfDaysToBeBlocked = jsonData.NoOfBlockedDays;
          unAvailableDates = [];
          var lastAvailability = checkLastAvailability();
          if (lastAvailability === 0) {
            noCurrentAvailablity = true;
          } else {
            findUnAvailableDates();
            if (providersFound()) {
              displayProviders();
              if (selectedProvider !== null) {
                fincUnavailableDatesForProvider();
              }
            } else {
              hideProviders();
            }
            loadingData = false;
            $('#datepicker').datepicker("refresh");
            clearCalendarSelection();
            noCurrentAvailablity = false;
          }
        },
        error: function error() {
          //console.log('ajax error ', url);
          loadingData = false;
        }
      });
    }
    function submitBooking() {
      clearErrors();
      var form = getForm();
      if (validateForm() === true && !$("#submit-booking-form").hasClass('submit-disabled')) {
        $("#submit-booking-form").addClass('submit-disabled');
        $.ajax({
          type: 'POST',
          url: selectedProvider ? '/webservices/booking.svc/addbookingwithprovider/' + centerId + '/' + selectedProvider + '/' + $theLang : '/webservices/booking.svc/addbooking/' + centerId + '/' + $theLang,
          data: JSON.stringify(form),
          contentType: "application/json; charset=utf-8",
          dataType: 'JSON',
          processdata: true,
          success: function success(data) {
            // GTM track submit form - success
            window.DGS.Event.getInstance().trigger('trackOBFormFillSuccess', centerName, appointmentName);
            var bookingResult = JSON.parse(data);
            if (bookingResult.RedirectUrl) {
              window.location = bookingResult.RedirectUrl;
            } else {
              if (bookingResult.Created === true) {
                var successHtml = successTemplate.format(bookingResult.Title, bookingResult.Text);
                resultContainer.html(successHtml);
              } else {
                var errorHtml = errorTemplate.format(bookingResult.Title, bookingResult.Text);
                resultContainer.html(errorHtml);
              }
              $("#submit-booking-form").removeClass('submit-disabled');
            }
          },
          error: function error() {
            // GTM track submit form - error
            window.DGS.Event.getInstance().trigger('trackOBFormFillError', centerName, appointmentName);
            resultContainer.html(errorTemplate);
            $("#submit-booking-form").removeClass('submit-disabled');
          }
        });
      } else {}
    }
    function defaultDate() {
      var newDate = new Date();
      newDate.setDate(newDate.getDate() + 1);
      var nMonth = newDate.getMonth() + 1;
      var nDate = newDate.getDate();
      var nYear = newDate.getFullYear();
      return AddLeadingZero(nMonth) + '-' + AddLeadingZero(nDate) + '-' + nYear;
    }
    function AddLeadingZero(value) {
      if (parseInt(value) < 10) return "0" + value;else return value;
    }
    function clearErrors() {
      $('#appointment-type-error').hide();
      $('#appointment-time-error').hide();
      $('#form-firstname-error').hide();
      $('#form-lastname-error').hide();
      $('#form-email-error').hide();
      $('#form-phonenumber-error').hide();
      $('#form-phonenumbertype-error').hide();
      $('#form-address-error').hide();
      $('#form-postcode-error').hide();
      $('#form-visit-before-error').hide();
      $('#form-18years-error').hide();
      $('#form-contact-method-error').hide();
      $('#form-comment-error').hide();
      $('#form-checkbox-error').hide();
      $('#form-worn-hearingaids-error').hide();
      $('#form-what-prompted-youtovisit-error').hide();
      $('#form-difficulty-hearing-error').hide();
    }
    function validateForm() {
      var errors = 0;
      if (!$('input:text[name=firstname]').val()) {
        $('#form-firstname-error').show();
        errors++;
      }
      if (!$('input:text[name=lastname]').val()) {
        $('#form-lastname-error').show();
        errors++;
      }
      if (!$('input:text[name=email]').val()) {
        $('#form-email-error').show();
        errors++;
      }
      if ($('input:text[name=email]').val().length > 0) {
        if (!validateEmail($('input:text[name=email]').val())) {
          $('#form-email-error').show();
          errors++;
        }
      }
      if (!$('input[name=phonenumber][type=tel]').val()) {
        $('#form-phonenumber-error').show();
        errors++;
      }
      if (isVisible('.booking-form-phonenumber-type') && !$('input[type=radio][name=PhoneNumberType]:checked').val()) {
        $('#form-phonenumbertype-error').show();
        errors++;
      }
      if (isVisible('.booking-form-address') && !$('input:text[name=address]').val()) {
        $('#form-address-error').show();
        errors++;
      }
      if (isVisible('.booking-form-postcode') && !$('input:text[name=postcode]').val()) {
        $('#form-postcode-error').show();
        errors++;
      }
      if (isVisible('.booking-form-visit') && !$('input[type=radio][name=VisitBefore]:checked').val()) {
        $('#form-visit-before-error').show();
        errors++;
      }
      if (isVisible('.booking-form-18years') && !$('input[type=radio][name=EighteenYears]:checked').val()) {
        $('#form-18years-error').show();
        errors++;
      }
      if (isVisible('.booking-form-contact-method') && !$('input[type=radio][name=ContactMethod]:checked').val()) {
        $('#form-contact-method-error').show();
        errors++;
      }
      if (isVisible('.booking-form-worn-hearingaids') && !$('input[type=radio][name=WornHearingAids]:checked').val()) {
        $('#form-worn-hearingaids-error').show();
        errors++;
      }
      if (isVisible('.booking-form-what-prompted-youtovisit') && $('input[type=checkbox][name="WhatPromptedYouToVisit[]"]:checked').length === 0) {
        $('#form-what-prompted-youtovisit-error').show();
        errors++;
      }
      if (isVisible('.booking-form-difficulty-hearing') && !$('input[type=radio][name=DifficultyHearing]:checked').val()) {
        $('#form-difficulty-hearing-error').show();
        errors++;
      }
      if (errors > 0) {
        // GTM track submit form - error
        window.DGS.Event.getInstance().trigger('trackOBFormFillError', centerName, appointmentName);
        return false;
      }
      return true;
    }
    function validateEmail(email) {
      var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(email);
    }
    function getForm() {
      var form = {};
      form.CenterId = centerId;
      form.When = when;
      form.From = from;
      form.To = to;
      form.AppointmentTypeId = appointmentTypeId;
      form.Firstname = $('input:text[name=firstname]').val();
      form.Lastname = $('input:text[name=lastname]').val();
      form.Email = $('input:text[name=email]').val();
      form.Phonenumber = $('input[name=phonenumber][type=tel]').val();
      form.CurrentItemID = $('input[name=CurrentItemID]').val();
      form.ReferralSource = $('input[name=ReferralSource]').val();
      if (isVisible('.booking-form-phonenumber-type')) {
        form.PhoneNumberType = $('input:radio[name=PhoneNumberType]:checked').val();
      }
      if (isVisible('.booking-form-address')) {
        form.Address = $('input:text[name=address]').val();
      }
      if (isVisible('.booking-form-postcode')) {
        form.Postcode = $('input:text[name=postcode]').val();
      }
      if (isVisible('.booking-form-visit')) {
        form.VisitBefore = $('input:radio[name=VisitBefore]:checked').val();
      }
      if (isVisible('.booking-form-18years')) {
        form.EighteenYear = $('input:radio[name=EighteenYears]:checked').val();
      }
      if (isVisible('.booking-form-contact-method')) {
        form.ContactMethod = $('input:radio[name=ContactMethod]:checked').val();
      }
      if (isVisible('.booking-form-comment')) {
        form.Comment = $('input:text[name=comment]').val();
      }
      if (isVisible('.booking-form-worn-hearingaids')) {
        form.WornHearingAids = $('input:radio[name=WornHearingAids]:checked').val();
      }
      if (isVisible('.booking-form-what-prompted-youtovisit')) {
        form.WhatPromptedToVisit = '';
        $.each($('input:checkbox[name="WhatPromptedYouToVisit[]"]:checked'), function () {
          form.WhatPromptedToVisit += $(this).val() + ', ';
        });
      }
      if (isVisible('.booking-form-difficulty-hearing')) {
        form.DifficultyHearing = $('input:radio[name=DifficultyHearing]').val();
      }
      if (isVisible('#policy-check-box')) {
        var isChecked = $('input[type=checkbox][name=PolicyCheckBox]').is(":checked");
        if (isChecked) {
          form.PolicyCheckBox = "Yes";
        } else {
          form.PolicyCheckBox = "No";
        }
      }
      return form;
    }
    function isVisible(selector) {
      return $(selector).is(":visible");
    }
  });
})(jQuery);