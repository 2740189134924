function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
(function ($) {
  "use strict";

  window.DGS.OnContentLoaded.getInstance().register(function () {
    $(".component.notification-overlay-button").each(function () {
      var vm = $(this);
      $(".redirect-popup").on("click", function (e) {
        var thisa = $(this);
        var thisattr = $(this).attr("href");
        if (_typeof(thisattr) !== (typeof undefined === "undefined" ? "undefined" : _typeof(undefined)) && thisattr !== false) {
          e.preventDefault();
          vm.addClass("shown");
          setTimeout(function () {
            location.href = thisattr;
          }, 2000);
        }
      });
    });
  });
})(jQuery);